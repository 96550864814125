<template>
    <div>
        <div class="mb-2 w-11/12 sm:w-9/12 mr-auto ml-auto">
            <module-title title="DPOS" :total="totalAddress"></module-title>
        </div>
        <div
            class=" mb-4 w-11/12 sm:w-9/12 mr-auto ml-auto rounded-lg shadow-lg border bg-white border-lightborder dark:bg-black200 dark:border-border100 dark:shadow">
            <dpos-list-table :dataList="dposListDatas" :loadStatus="dposListLoadStatus" />
            <div class="">
                <h-pagination @changePageSize="toDposFirstPage" @toFirstPage="toDposFirstPage"
                    @toPrePage="toDposPrePage" @toNextPage="toDposNextPage" @toLastPage="toDposLastPage"
                    :currentPage="dposCurrentPage" :totalPage="totalPage"
                    @toTargetPage="toDposTargetPage"></h-pagination>
            </div>
        </div>
    </div>
</template>


<script>
import ModuleTitle from '@/components/public/ModuleTitle'
import HPagination from '@/components/public/HPagination'
import DposListTable from '@/components/child/DposListTable'
import { listDelegate } from '@/request/dpos'
import { numberFormat } from '../../utils/format'
export default {
    components: { DposListTable, ModuleTitle, HPagination, },
    name: "dpos",
    data() {
        return {
            dposListDatas: [],
            dposListLoadStatus: 'loading',
            dposPageSize: 10,
            dposCurrentPage: 1,
            totalAddress: 0,
            totalPage: 1
        }
    },
    created() {
        this.getListDelegate()
    },
    methods: {
        numberFormat,
        getListDelegate() {
            this.dposListLoadStatus = 'loading'
            listDelegate({ pageSize: this.dposPageSize, page: this.dposCurrentPage, chainid: localStorage.getItem('chainID') }).then(res => {
                if (res.data.length !== 0) {
                    this.dposListDatas = res.data
                    this.dposListLoadStatus = 'finished'
                } else {
                    this.dposListLoadStatus = 'empty'
                }
                console.log(res)
                // this.totalAddress = this.$t('moduleTitle.totalNode', { count: numberFormat(res.total) })
                this.totalAddress = res.total
                this.totalPage = res.totalPage
                this.dposCurrentPage = res.page

            });
        },
        toDposFirstPage(selectedPageSize) {
            console.log('第一页')
            this.dposPageSize = selectedPageSize
            this.dposCurrentPage = 1
            this.dposListDatas = []
            this.getListDelegate()
        },
        toDposPrePage(selectedPageSize) {
            if (this.dposCurrentPage === 1) {
                return
            }
            this.dposPageSize = selectedPageSize
            this.dposCurrentPage = this.dposCurrentPage - 1
            this.dposListDatas = []
            this.getListDelegate()
        },
        toDposNextPage(selectedPageSize) {
            this.dposPageSize = selectedPageSize
            this.dposCurrentPage = this.dposCurrentPage + 1
            this.dposListDatas = []
            this.getListDelegate()
        },
        toDposLastPage(selectedPageSize) {
            console.log(this.dposCurrentPage, this.totalPage)
            if (this.dposCurrentPage >= this.totalPage) {
                return
            }
            this.dposPageSize = selectedPageSize
            this.dposCurrentPage = this.totalPage
            this.dposListDatas = []
            this.getListDelegate()
        },
        toDposTargetPage(selectedPageSize, targetPage) {
            console.log(targetPage)
            if (targetPage <= 0) {
                return
            }
            this.dposPageSize = selectedPageSize
            this.dposCurrentPage = targetPage
            this.dposListDatas = []
            this.getListDelegate()
        }
    },
}
</script>

<style>
h1,
h2 {
    font-weight: normal;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {}

a {
    color: #2d8cf0;
}
</style>